.care-worker-map {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .section-wrapper {
    display: flex;
    height: 100%;

    span {
      display: block;
    }

    .title {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    .sidebar {
      width: 300px;
      max-height: 100%;
      padding: 0.5rem;
      overflow-y: auto;

      .view-eye {
        text-align: center;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .map-wrapper {
      flex-grow: 1;
      padding: 0.5rem;

      .info-panel {
        max-width: 300px;
      }

      .worker-pin {
        z-index: auto;
      }

      .point-pin {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: auto;
        border: 2px solid #000000;

        &.upcoming {
          background: map-get($points, 'upcoming');
        }

        &.past {
          background: map-get($points, 'past');
        }

        &.active {
          background: map-get($points, 'active');
        }
      }
    }
  }
}
