.navbar {
  padding-top: 0rem;
  padding-bottom: 0rem;
  height: $navbar-height;
}

.company-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: $navbar-height;
  margin-right: 10px;
  background-color: $white;
  border: solid 0.25rem #00568d;
}

.company-logo-img {
  max-height: 100%;
  object-fit: contain;
  height: auto;
}
.navbar {
  .container-fluid {
    padding-left: 0px !important;
  }
}
.company-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: $black;
  margin: 5px;
}

.menu {
  background-color: theme-color('menu-background');
  font-size: 14px;

  .menu-link {
    color: theme-color('menu-foreground');
    margin: auto -0.25rem -2px auto;
  }

  .menu-brand:hover,
  .menu-link:hover,
  .menu-link.active {
    color: theme-color('hovered-foreground');
    background-color: theme-color('menu-foreground');
  }

  .menu-dropdown-item {
    font-size: 14px;

    &:hover {
      background-color: theme-color('menu-background');
      color: theme-color('menu-foreground');
    }

    &.active:not(:hover),
    &:active:not(:hover) {
      background-color: theme-color('menu-background');
      color: theme-color('menu-foreground');
      opacity: 0.6;
    }
  }

  .menu-user {
    color: theme-color('menu-foreground');
  }

  .menu-logout {
    color: theme-color('menu-foreground');
    font-weight: bold;
  }

  .dropdown-menu {
    background-color: theme-color('menu-foreground');
    border-color: theme-color('menu-border');
    border-top: 0 !important;
    border-radius: 0 !important;
  }
}

.quick-menu {
  max-width: 220px;
  height: 100%;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0;
  background-color: theme-color('quick-menu-background');

  .header {
    background: linear-gradient(to bottom, theme-color('header-start'), theme-color('header-end'));
    font-weight: bold;
    height: $header-height;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.narrow {
    max-width: 40px;
  }

  .quick-menu-icon {
    background-color: $black;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.appointments {
      background-color: theme-color('appointments');
    }
    &.transport {
      background-color: theme-color('transport');
    }
    &.prospects {
      background-color: theme-color('prospects');
    }
    &.care-workers {
      background-color: theme-color('care-workers');
    }
    &.company-crm {
      background-color: theme-color('company-crm');
    }
    &.leave-requests {
      background-color: theme-color('leave-requests');
    }
    &.invoices {
      background-color: theme-color('invoices');
    }
    &.payroll {
      background-color: theme-color('payroll');
    }
    &.documents {
      background-color: theme-color('documents');
    }
    &.reports {
      background-color: theme-color('reports');
    }
    &.other-config {
      background-color: theme-color('other-config');
    }
  }

  .quick-menu-item {
    font-size: 13px;
    color: theme-color('quick-menu-foreground');

    .icon {
      font-size: medium;
    }

    &.parent {
      font-weight: bold;
    }

    &.child {
      font-weight: normal;
    }
  }

  ul {
    margin: 0;
    list-style: none;
    padding-left: 1rem;
  }

  .dropend .dropdown-menu {
    top: -10px !important;
    right: auto !important;
    left: 100% !important;
    transform: translateX(-40px) !important;
    background-color: theme-color('menu-background');
    font:
      normal 12px/15px 'Arial',
      Helvetica,
      sans-serif !important;
    border: 1px solid #dca;
    background: #fffaf0;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;

    -moz-box-shadow: 5px 5px 8px #ccc;
    -webkit-box-shadow: 5px 5px 8px #ccc;
    box-shadow: 5px 5px 8px #ccc;
  }
}

.quick-menu-dropdown-item {
  &:hover {
    background-color: theme-color('menu-background');
    color: theme-color('menu-foreground');
  }

  &.active:not(:hover),
  &:active:not(:hover) {
    background: #fffaf0;
    color: $black;
  }
}

.prevent-nav {
  .menu {
    display: none !important;
  }

  .quick-menu {
    display: none !important;
  }

  .nav {
    display: none !important;
  }

  .nav-element {
    display: none !important;
  }
}
