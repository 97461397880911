.bidding-card {
  display: flex;
  align-items: center;
  padding: 0 0.25rem;

  .status {
    width: 1.5rem;
    text-align: center;

    &.rejected {
      color: $red;
    }
  }

  input {
    width: 1.5rem;
    height: 1.5rem;
  }
}
