.form-image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;

  .image-box {
    width: 150px;
    height: 150px;
    border: 2px solid #ccc;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
  }

  .download-link {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
  }
}
