.btn-super-sm {
  @include button-size(0.125rem, 0.25rem, $btn-font-size-sm, $btn-border-radius-sm);
}

.notification-button {
  position: relative; // Makes the button the reference point for the badge
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.count {
  position: absolute;
  top: -5px;
  left: -2.5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1;
}
