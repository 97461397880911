.award-alerts-container {
  width: 100%;
  border-top: 0;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 10px;

    &.underline {
      border: none;
      border-bottom: 1px solid #ccc;
      margin: 10px 0;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
}
