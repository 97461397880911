.week-picker {
  .MuiDayCalendar-weekDayLabel {
    max-width: 36px;
  }
  .day {
    border-radius: 0;
    transition: all 100ms ease-in-out;

    &[aria-colindex='1'] {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &[aria-colindex='7'] {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    &.selected {
      background-color: theme-color('selected-background');
      color: theme-color('selected-foreground');

      &[class*='-today '] {
        border: 1px solid theme-color('selected-foreground');
      }
    }

    &.hovered:not(.selected) {
      background-color: theme-color('selected-background');
      color: theme-color('selected-foreground');
      opacity: 0.8;
    }
  }
}
