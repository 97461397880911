.side-bar-container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;

  .filter-menu {
    display: flex;
    gap: 0.2rem;
    .action-view {
      cursor: pointer;
      display: flex;
      align-items: center;
      vertical-align: bottom;
      font-size: 1rem;
      color: $white;
      background-color: $secondary;
      border: none;
      border-radius: 10%;
      gap: 0.25rem;
      padding: 0.1rem 0.2rem;

      &:hover {
        border-radius: 10%;
        background-color: $black-fade;
        color: $white !important;
      }

      &.active,
      &:active {
        border-radius: 10%;
        background-color: $secondary;
        color: $white;
      }
    }

    label {
      cursor: pointer;
    }
  }

  .accordion-item {
    position: relative;
    flex: 0 1 $weekly-planner-sidebar-header-height;

    &.expanded {
      flex: 1 1 auto;
    }
  }

  .accordion-collapse {
    &.show {
      .accordion-body {
        position: absolute;
        inset: 0;
        top: $weekly-planner-sidebar-header-height;
      }
    }
  }

  .accordion-body {
    padding: 0;
  }

  .card-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .accordion {
      display: flex;
      flex-direction: column;

      .accordion-item {
        flex-shrink: 0;
      }
    }

    .container {
      padding: 0;
    }

    .nav {
      top: 0;
      display: flex;
      flex-shrink: 0;
      justify-content: end;
      align-items: center;
      border-radius: 0 !important;
      padding: 0.5rem;
      margin-bottom: 0.25rem;
      background-color: theme-color('sidebar-header');
      z-index: 1;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;

      &.underline {
        border: none;
        border-bottom: 1px solid $weekly-planner-background-colour;
        margin: 10px 0;

        &:last-of-type {
          border-bottom: none;
          margin-bottom: 0;
        }
      }
    }

    .no-result {
      text-align: center;
      padding-top: 0.5rem;
      font-weight: bold;
    }
  }

  &.is-dragging-over {
    background-color: $lightgreen;
  }

  &.is-dragging-origin {
    z-index: 10 !important;
  }
}

.modal-body-scrollable {
  max-height: 400px;
  overflow-y: auto;
}

@import 'award-alerts';
@import 'missing-qualifications';
@import 'rejected';
@import 'saveload';
@import 'unallocated';
