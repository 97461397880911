.wizard-modal {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  height: 100%;

  .header {
    background: linear-gradient(to bottom, theme-color('header-start'), theme-color('header-end'));
  }

  .wizard-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    flex-shrink: 0;
    flex-grow: 1;
  }

  .wizard-form-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }

  .wizard-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
  }

  .footer-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .footer-buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .footer-buttons-wrapper button {
    width: auto;
  }

  .footer-buttons-wrapper .previous-button {
    margin-right: auto;
  }

  .footer-buttons-wrapper .next-button {
    margin-left: auto;
  }

  .wizard-footer .footer-buttons-wrapper:only-child {
    justify-content: flex-end;
  }
}
