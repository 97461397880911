.container-loading {
  position: relative;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: black;

    &.transparent {
      opacity: 0.6;
    }
  }

  .spinner {
    position: absolute;
    width: 100%;
    top: 3rem;
    z-index: 3;
  }
}
