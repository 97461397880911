$prospect-gradient: linear-gradient(to top, theme-color('prospect-start'), theme-color('prospect-end'));

.prospect-statistics {
  .statistics-wrapper {
    display: flex;
    width: 100%;

    .card {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #ddd;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .card-body {
      background-image: $prospect-gradient;
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 2rem; /* Add gap between icon and text */
    }

    .card-header {
      background-color: $warning;
      font-size: 0.9rem;
      font-weight: bold;
      color: $white;
      padding: 0.5rem;
      border-radius: 8px 8px 0 0;
    }

    .card-icon {
      text-align: left;
      margin-bottom: 1rem;
    }

    .statistics-content {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
    }

    .statistic-item {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      flex-shrink: 0;
      min-height: 2rem;
      .btn {
        font-size: small;
      }
    }
  }
}
