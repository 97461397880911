.service-colour {
  .colour-body {
    .colour-table {
      max-height: 60vh;
      overflow-y: auto;

      .edit-icon {
        visibility: hidden;
      }

      .service-title:hover {
        cursor: pointer;
        text-decoration: underline;
        .edit-icon {
          visibility: visible;
        }
      }
    }
    .react-colorful__pointer {
      z-index: auto;
    }

    .colour-panel {
      border: 1px solid lightgray;
      border-radius: 2%;
      padding: 0.5rem;
      margin-top: 1rem;

      .is-invalid {
        z-index: 0;
      }

      .check-icon {
        font-size: 30px;
        padding-left: 0.8rem;
        color: green;
        vertical-align: middle;
      }

      .picker-section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .picker {
          align-self: center;
        }

        .recent {
          display: flex;
          justify-content: center;
          text-align: center;
          margin-top: 0.5rem;

          .row {
            width: 200px;
            border: none;
            border-radius: 0.5rem;
            background-color: #e3e3e3;
            padding: 0.5rem;

            .colour-boxes {
              display: flex;
              justify-content: center;

              .colour-box {
                margin-left: 0.25rem;
                margin-right: 0.25rem;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
