// All appointment cards
.appointment-card {
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .appointment-info {
    padding: 0.25rem 0.5rem;
  }

  &.shift-pay {
    opacity: 0.5;
  }

  &.cancelled {
    opacity: 0.5;
  }

  &.changed {
    .status-strip {
      background: repeating-linear-gradient(-45deg, yellow, yellow 5px, black 5px, black 10px);
    }
  }

  &.new {
    .status-strip {
      background: repeating-linear-gradient(-45deg, lightblue, lightblue 5px, black 5px, black 10px);
    }
  }

  &.flexible {
    background-color: theme-color('appointment-flexible') !important;
  }

  &.non-flexible {
    background-color: theme-color('appointment-non-flexible') !important;
    color: $white !important;
  }

  &.on-hold {
    border: 4px solid $on-hold !important;
  }
}

// Drag and drop extension
.dnd-overlay {
  position: relative;
  .appointment-card {
    display: flex;
    border: 1px solid black;
    background-color: #fff;
    position: relative;

    .status-strip {
      width: 10px;
      flex-shrink: 0;
    }
  }
}

// Weekly view extension
.weekly-view-wrapper {
  .appointment-card {
    display: flex;
    border: 1px solid black;
    background-color: #fff;
    position: relative;
    width: 100%;

    .status-strip {
      width: 10px;
      flex-shrink: 0;
    }
  }
}

// Daily view extension
.daily-view-container {
  .appointment-card {
    position: relative;
    overflow: visible;
    border: 1px solid black;

    .status-strip {
      height: 10px;
    }

    .focus-distance-segment {
      height: 10px;
      background-color: red;
    }
  }
}
