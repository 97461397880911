.dashboard-alerts {
  .alert-banner {
    margin-top: 0.5rem;

    .lead {
      margin-bottom: 0 !important;
      font-weight: bold;
    }
  }
}
