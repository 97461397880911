.container-panel {
  .side-panel {
    display: flex;
    flex-direction: row;
    transition: flex 100ms ease-in-out;

    min-width: 20%;
    max-width: 50%;
    position: relative;

    .content {
      flex: 1;
    }

    .sizer {
      flex: 0;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 6px;
      justify-self: flex-end;
      cursor: col-resize;
      resize: horizontal;
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
      position: relative;

      &:hover {
        width: 3px;
        background: var(--bs-gray-400) !important;

        + .toggle {
          display: flex;
        }
      }
    }

    .toggle {
      cursor: pointer;
      border: 1px solid var(--bs-gray-400);
      border-radius: 50%;
      transition: background-color 100ms ease-in-out;
      background-color: white;
      position: absolute;
      top: 50px;
      right: -5px;
      z-index: 20;
      height: 25px;
      width: 25px;
      display: none;
      justify-content: center;
      align-items: center;

      &:hover {
        display: flex;
        background-color: var(--bs-gray-300);
      }

      &:active {
        display: flex;
        background-color: var(--bs-gray-400);
      }

      .icon {
        color: var(--bs-gray-700);
        height: 0.75em;
      }
    }

    &.collapse {
      min-width: auto;
      width: 25px !important;

      &.no-gap {
        width: 0px !important;
      }

      .content,
      .sizer {
        display: none !important;
      }

      .toggle {
        display: flex;
        left: -5px;
        right: auto;
      }
    }
  }

  .main-panel {
    width: fit-content;
  }
}
