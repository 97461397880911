.send-details-modal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 15vh;
  justify-content: center;

  .send-details-modal-field {
    width: 50%;
    flex-shrink: 0;
  }

  .send-details-modal-container {
    gap: 0.5rem;
    display: flex;
  }
}
