.focus-bar-container {
  background-color: theme-color('header-end');
  height: 100%;
  width: 100%;
  padding-top: 0.25rem;

  .focus-card {
    display: flex;
    padding-left: 0.25rem;

    .unfocus {
      padding: 0.25rem 0.25rem 0.25rem 0;
    }
  }

  .unallocated-card {
    margin: 0.25rem;
    border: 1px solid $black;
    background-color: $white;
    display: flex;

    .status-strip {
      width: 10px;
      flex-shrink: 0;
    }

    .extras {
      display: none;
    }
  }

  .sections {
    background-color: inherit;

    .accordion-item,
    .accordion-header,
    .accordion-button {
      background-color: inherit;
    }

    .accordion-item {
      border-top: 2px solid theme-color('menu-border');
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      border-radius: 0;
    }

    .accordion-header {
      border-bottom: 1px solid theme-color('menu-border');
    }

    .accordion-button {
      font-weight: bold;
      padding: 0.5rem 1rem;
    }
  }

  .section,
  .accordion-body {
    padding: 0.5rem;

    .separated {
      border-top: 1px dashed theme-color('hovered-background');
    }

    .row {
      padding: 0.5rem;

      &.center {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .label {
      font-weight: bold;
    }

    .allocate {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
}
