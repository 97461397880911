.snapshots {
  height: 600px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;

  .layout {
    position: relative;
    overflow: visible;

    .snapshot-card {
      background: white;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      overflow: hidden;
    }
  }
}
.snapshot-container {
  height: 100%;
  width: 100%;
  .card {
    height: 100%;
    width: 100%;
  }
  .card-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
