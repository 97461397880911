// Add custom colours
$custom-colors: (
  'menu-background': #0066a6,
  'menu-foreground': #fff,
  'menu-border': #e5eff6,
  'selected-background': #0066a6,
  'selected-foreground': #fff,
  'hovered-background': #4c93c0,
  'hovered-foreground': #000,
  'quick-menu-background': #ecf2f9,
  'quick-menu-foreground': #000,
  'header-start': #4d94ff,
  'header-end': #a6caff,
  'appointments': #889e0c,
  'budgets-statements': #7c88e8,
  'transport': #7ab32c,
  'care-workers': #3e875a,
  'company-crm': #a72c06,
  'leave-requests': #db4a1e,
  'prospects': #e88c0d,
  'invoices': #7c88e8,
  'payroll': #7c88e8,
  'documents': #1892a0,
  'reports': #5bb1e1,
  'other-config': #7c7c7c,
  'week-picker-hover': #dee2e6,
  'appointment-flexible': #13fc03,
  'appointment-non-flexible': #f50b0b,
  'sidebar-header': #d4d4d4,
  'overdue-payment-banner': #f7cecf,
);

$header-colors: (
  'info-start': #4d94ff,
  'info-end': #a6caff,
  'warning-start': #faa51a,
  'warning-end': #f47a20,
  'weekly-planner-start': #5cb85c,
  'weekly-planner-end': #376e37,
  'prospect-start': #f4a421,
  'prospect-end': #fde4bb,
);

$points: (
  'upcoming': #07cf36,
  'past': #daa807,
  'active': #e00707,
);

$container-min-width: 1180px;

$header-height: 40px;
$navbar-height: 50px;

$white: #ffffff;
$black: #000000;
$black-fade: #5e5e5e;
$on-hold: #7b90d6;
$orange: #ffa500;
$red: #ff0000;
$green: #008000;
$white: #ffffff;
$tea-break: #92d8e4;
$lightgreen: #90ee90;

$daily-view-cols: 288;
$daily-view-screen-width: 3200px;
$daily-view-cell-span: repeat($daily-view-cols, calc($daily-view-screen-width / $daily-view-cols));
$weekly-planner-background-colour: #ccc;
$weekly-planner-sidebar-header-height: 50px;

// Merge custom with existing theme colors
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors: map-merge($theme-colors, $header-colors);
