// Set default font family
$font-family-sans-serif: 'Arial', 'Helvetica', sans-serif;

// Override colour defaults before importing bootstrap modules
$primary: #00b4ae;
$secondary: #1e384c;
$warning: #faa51a;
$disabled: #adb5bd;

// Import bootstrap modules, functions, etc.
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

// Import custom modules to add to bootstrap style
@import './modules/index';

// Import generic component specific modules to add to global stylings.
@import './components/index';
