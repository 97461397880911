.budget-container {
  border: 1px solid #000;
  border-radius: 12px;
  padding: 1rem;
  background-color: #fff;
}

.budget-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.budget-table {
  width: 100%;
  border-collapse: collapse;
}

.budget-table th,
.budget-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.budget-table th {
  background-color: #f4f4f4;
}

.budget-item-form {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.empty-message {
  text-align: center;
  font-style: italic;
  color: #666;
}

.budget-item-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
}

.form-group {
  flex: 1;
  min-width: 180px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-actions {
  display: flex;
  gap: 8px;
  margin-top: 10px;
}
