.save-load-container {
  width: 100%;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 10px;

    &.underline {
      border: none;
      border-bottom: 1px solid #ccc;
      margin: 10px 0;
    }

    &.content {
      display: flex;
      justify-content: flex-start;
      padding-left: 0.25rem;

      .row-header {
        font-size: 0.75rem;
      }

      .row-center {
        display: flex;
        justify-content: center;
        span {
          font-size: 1rem;
          text-align: center;
        }
        small {
          margin-top: 0.25rem;
          text-align: center;
        }
      }
    }
  }

  span,
  input,
  select,
  label {
    flex: none;
    width: 48%;
  }

  label {
    font-weight: bold;
  }

  span {
    font-size: 12px;
  }

  select {
    width: 100%;
    padding: 5px;
  }

  .btn {
    max-width: 240px;
    &.publish {
      background-color: #1e90ff;
      color: white;
      border-color: #1e90ff;

      &:hover {
        background-color: darken(#1e90ff, 10%);
      }
    }
    &.import {
      background-color: #ff8c00;
      color: white;
      border-color: #ff8c00;

      &:hover {
        background-color: darken(#ff8c00, 10%);
      }
    }
  }
}
