.week-schedule {
  height: 750px !important;
  min-width: 1400px !important;
  position: relative;
  overflow: hidden;

  .main-view {
    height: 85% !important;
    padding-bottom: 1rem;
  }

  .dashboard-container {
    grid-template-rows: 60px auto !important;
    scrollbar-gutter: unset !important;
    overflow-y: none;
  }

  .center-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
