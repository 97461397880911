.legend {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  margin: 0 0.25rem;

  hr {
    margin: 2px;
  }

  .legend-title {
    text-align: center;
  }

  .legend-item {
    display: grid;
    grid-template-columns:
      150px
      1fr;
    gap: 0.25rem;
  }

  .legend-item:active {
    color: $black;
  }

  .legend-item:hover {
    background: transparent;
  }
  .dropdown-item:active {
    background: transparent;
  }

  .icon {
    height: 1rem;
    text-align: center;
  }
}
