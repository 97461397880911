.notifications {
  padding: 0;
  list-style: none;

  .notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  .accordion-body {
    padding: 0;
  }
  .no-text {
    padding: 1.25rem;
  }

  .notification:hover {
    background-color: #f5f5f5;
  }

  .notification .notification-content {
    display: flex;
    justify-content: space-between;
    place-items: center;
    flex-grow: 1;

    width: 100%;
  }

  .datetime {
    font-size: 0.8rem;
    color: #777;
  }

  .message {
    font-size: 1rem;
    color: #333;
    flex-grow: 1;
    margin-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .notification > .fa-icon {
    font-size: 1.25rem;
    color: #007bff;
  }

  .seen {
    color: #00796b;
  }

  .new-notifications-header {
    font-size: 1.1rem;
    font-weight: bold;
    color: #007bff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .new-notifications-header .notification-count {
    font-weight: bold;
    color: #007bff;
    margin-left: 10px;
  }

  .acknowledged-notifications-header {
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 4px;
  }
}
