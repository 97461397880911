.unallocated-appointments {
  .greyed-out {
    background-color: rgba(128, 128, 128, 0.2);
  }

  .action-view {
    cursor: pointer;
  }

  .unallocated-row {
    display: grid;
    grid-template-columns: auto 25px;
    align-items: center;

    .action-menu-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .action-toggle {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: theme-color('info-end');
        }

        &:active {
          background-color: theme-color('info-start');
        }
      }
    }
  }

  .search-icon:hover {
    cursor: pointer;
  }
}
