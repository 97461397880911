.upcoming-appointments {
  border: 1px solid #ddd;
  border-radius: 4px;

  .all-upcoming-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding: 0.25rem;
    display: flex;
    gap: 0.25rem;
    margin-top: 0.25rem;
  }

  .body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.25rem;

    .appointment-card {
      flex: 0 0 auto;
      width: 100%;

      .card-header {
        padding: 0;

        .status-strip {
          justify-self: end;
          width: 100%;
          height: 5px;
        }

        .header-info {
          padding: 0.5rem;
          .header-title {
            font-weight: bold;
          }
        }
      }

      .card-body {
        padding: 0.75rem;

        .section-title {
          font-weight: bold;
          font-size: 1rem;
        }
      }

      .options {
        small {
          display: block;
          margin-top: 0.25rem;
        }
      }
    }

    @media (min-width: 768px) {
      .appointment-card {
        width: calc(100% / 2);
      }
    }
    @media (min-width: 992px) {
      .appointment-card {
        width: calc(100% / 3);
      }
    }
    @media (min-width: 1400px) {
      .appointment-card {
        width: calc(100% / 4);
      }
    }
    @media (min-width: 1800px) {
      .appointment-card {
        width: calc(100% / 6);
      }
    }
  }
}
