// All unallocated cards
.unallocated-card {
  position: relative;
  cursor: grab;
  border-bottom: 1px solid $black;

  .content {
    padding: 0.25rem;
    margin: 0.25rem 0;

    .appointment-content {
      display: flex;
      justify-content: space-between;
    }

    .service {
      color: $green;
    }
    .qualifications {
      margin: 0.25rem 0;
    }

    .extras {
      display: flex;
      justify-content: space-between;

      .preference {
        &.not-available,
        &.not-qualified {
          color: theme-color('warning');
        }
      }
    }

    .bidding {
      font-weight: bold;
      margin-top: 0.25rem;

      &.rejected {
        color: $red;
      }
    }
  }

  .action-toggle {
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: theme-color('info-end');
    }

    &:active {
      background-color: theme-color('info-start');
    }
  }
}

.dnd-overlay {
  .unallocated-card {
    background-color: $white;
    border: 1px solid $black;
    max-width: 300px;

    .qualifications,
    .is-repeat {
      display: none;
    }
  }
}
